import React from "react"

import Layout from "../components/layout"
import ProjectNav from "../components/project-nav"
import Seo from "../components/seo"
import mockup from "../images/ehb/mockup.png"
import screenflow from "../images/ehb/screenflow.png"
import sticky from "../images/ehb/sticky.png"
import personas from "../images/ehb/personas.png"
import ideasprint from "../images/ehb/ideasprint.png"
import crazy8 from "../images/ehb/crazy8.png"

const SecondPage = () => (
  <Layout>
    <Seo title="Learning Platform for EHB" />
    <div className="light-grey">
      <ProjectNav
        className="small-space-top"
        previous="/tiny-house"
        next="/dan-flavin"
      />
      <div className="content grid grid--2 space smaller-space-top">
        <div className="col-1">
          <h2>
            Learning Platform
            <br />
            for EHB
          </h2>
          <p className="teaser-text">
            The task of this project was to create a digital learning
            environment for{" "}
            <a
              href="https://www.sfivet.swiss/"
              title="Swiss Federal Institute for Vocational Education and Training"
              target="_blank"
              rel="noreferrer"
            >
              EHB
            </a>{" "}
            that motivates students to keep on learning and help them recognize
            their progress.
          </p>
        </div>
        <img className="col-1" src={mockup} alt="Mockup" />
      </div>
    </div>
    <div className="content space">
      <img className="space-below" src={screenflow} alt="Mockup" />
      <div className="grid space-below">
        <div className="col-1">
          <h3>Conceptional work</h3>
          <p>
            The first step of most projects is to work on the concept. While
            brainstorming we worked in a group of four using{" "}
            <a href="https://miro.com/" target="_blank" rel="noreferrer">
              Miro
            </a>
            .
          </p>
        </div>
        <img
          className="col-2 shadow"
          src={sticky}
          alt="Brainstorming using sticky notes"
        />
        <div className="col-1">
          <p>
            Creating personas was important to understand who the user of the
            platform is. The users are students from any direction of field -
            they study how to teach their profession. The range of professions
            is diverse, so the design needs to work for people with very
            different digital skills.
          </p>
        </div>
        <img className="col-2 shadow" src={personas} alt="Personas" />
      </div>

      <div className="grid small-space-below">
        <div className="col-1">
          <h3>
            Finding Ideas
            <br />
            by drawing freely
          </h3>
          <p>
            Drawing freely without any judgement gets you to new ideas. In this
            project, we used the method{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.prototypr.io/how-to-run-a-crazy-eights-workshop-60d0a67b29a"
            >
              "Crazy 8"
            </a>{" "}
            in order to quickly generate many of them.
          </p>
        </div>
      </div>
      <div className="grid grid--2">
        <img className="col-start col-1 shadow" src={crazy8} alt="Crazy 8" />
        <img className="col-1 shadow" src={ideasprint} alt="Idea Sprint" />
      </div>
    </div>
    <ProjectNav
      className="space-below"
      previous="/tiny-house"
      next="/dan-flavin"
    />
  </Layout>
)

export default SecondPage
